import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/tailwind.css";
import { FormInput } from "./components/formInput";
import { jsPDF } from "jspdf";
import ReCAPTCHA from "react-google-recaptcha";
import { MainPage } from "./Home";
import { FormTwoColumnView } from "./components/formTwoColumnView";
import { FormSelect } from "./components/formSelect";
import { CLASS, GRADE, MATERIALS, SUBJECT } from "./constants";
import FormAutocompleteDropdown from "./components/formAutoComplete";


export const Contact = () => {
  const captchaRef = React.useRef(null);
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  
  return (
    <div className="mt-20">
      <div className="text-left px-20 h-[400px]">
      <div className="fs-1 fw-bold "
      
      >Drop us a line
        
      </div>

      <div className="fs-3  mt-20"
      
      >Have questions or need help? Send us an email <a href="mailto:support@newlessonplan.com" className="text-info">support@newlessonplan.com</a>, and we'll get back to you soon.
        
      </div>
  
      </div>
      {/* <div className="m-20">
        <div className="row">
        <div className="col-lg-6">
        <FormInput label="Your Email" text={email} onTextChange={t => setEmail(t)}  />
        </div>
       
        </div>

        <div className="col-lg-6">
        <FormInput label="Your feedback/request...."  text={message} onTextChange={t => setMessage(t)} isTextArea />
        </div>


        <ReCAPTCHA
          ref={captchaRef}
          sitekey={`6LccdQsqAAAAAPjYyZna8d_l666BsSYLblpoVGhP`}
          onChange={() => {
            //enable main button
          }}
        />

        <div
          className="btn btn-info w-100 mt-10 px-10 py-3"
          onClick={() => {
            const doc = new jsPDF();

            doc.text("Hello world!", 10, 10);
            doc.save("a4.pdf");
          }}
        >
         Send
        </div>
     
      </div> */}
    </div>
  );
};
