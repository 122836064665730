import React from 'react'
import { COUPON_ITEM_KEY, showToast } from './constants'
import DataModel from './DataModel'
import { ProgressLoadingView } from './components/progressview';
import { FormInput } from './components/formInput';
import { callCouponSvc, emailCoupon } from './utils/vGenerator';
import { validateEmail } from './NewCurriculum';

export const VoucherSuccess = () => {
    const [email, setEmail] = React.useState('')
    const [valid, setValid] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [currentCoupon, setCurrentCoupon] = React.useState('')
    const [o, setO] = React.useState(null)
    const [didSendEmail, setDidSendEmail] = React.useState(false)
    const process = async () => {
        
        const s =  sessionStorage.getItem(COUPON_ITEM_KEY);
        try {
            if(global.isProcessing) {
                return;
            }
            global.isProcessing = true;
            
            if(s?.includes("NLP-")) {
                setValid(true)
                setIsLoading(true)
              const result = await callCouponSvc(s, true)
              if(result?.isSuccess && result?.coupon) {
                setCurrentCoupon(result?.coupon)
                setIsLoading(false)
               sessionStorage.removeItem(COUPON_ITEM_KEY)
              }
                    // call cloud service to save voucher
            
            }
            else {
              global.isProcessing = false;
              window.location.href = '/'
            }
        }
        catch (e) {
        
        window.location.href = '/'
        }
        finally {
            global.isProcessing = false;
        }
    }
    const prefix =  `  Success.`
    const getDownloadDoneMessage = () => {
        
        let msg = `Save this voucher code to use for curriculum purchases.`
        
        return msg; 
    }

    React.useEffect(() => {
        process()

    }, [])

    if(!valid) {
        return <></>
    }
    return(
    <div>
    <div className=' text-center  m-10 p-10 mt-10 rounded mb-10 mt-5 mb-60 bg-[#0dcaf0] ' style={{maxWidth: '80%', alignSelf:'center', margin: '0 auto', marginBottom: 200, background:isLoading || 1===1?  'rgba(1, 1, 1, .03)': 'rgb(120 235 23 / 12%)'}}>
        <p className={`fs-1 ${isLoading ? ' fw-bold' : ''}`}>{
            isLoading ? ' Finalizing Your Purchase. Please wait...' :
            <div>
            <div className='row ' >
            <div className='bi bi-check-all  fw-bold' style={{color: global.green}}>{prefix}</div>
            </div>
            <div className={`h2 m-10 `}>
            {getDownloadDoneMessage()}
            </div>
            </div>
            
            }</p>

            {
                isLoading ? <div className='text-center w-100 p-20 h-[300px]'>
                <ProgressLoadingView text=' '/>
                </div>
                :
                <div className='mt-20'>
                
               {currentCoupon ? <CouponCard couponCode={currentCoupon}/>  : ''}
           
           
             {!didSendEmail ? <div className='mt-5 w-80 align-items-center ' style={{margin: '0 auto'}}>
              <hr/>
                <p className='py-5'>Want this coupon emailed to you? Enter your email below</p>
                
                <FormInput text={email} onTextChange={t => setEmail(t)}
                />
                 <div className='btn btn-info'
                onClick={async () => {
                  if(email?.length &&  validateEmail(email)) {
                    await emailCoupon(email, currentCoupon)
                    showToast('Email sent successfully')
                    setDidSendEmail(true)
                  }
                }}
                >
                    Email Me The Voucher Code
                </div>
              </div>
            : <div className=' bg-light-success p-5 mt-20 text-success'>
              Email sent successfully
                
            </div>  
            }
              </div>

            }
            </div>

            <div
            onClick={() => window.location.href = '/how-reimbursement-works'}
            className='text-center fs-5 text-indigo-500 underline cursor-pointer'>
              Click to learn how to get reimbursed for this voucher.
            </div>
    </div>)
}





const CouponCard = ({couponCode = ''}) => {
  const [copySuccess, setCopySuccess] = React.useState(false);

  

  const handleCopy = () => {
    navigator.clipboard.writeText(couponCode).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Hide the success message after 2 seconds
    });
  };

  return (
    <div
      onClick={handleCopy}
      className="card p-10 fs-1 fw-bold position-relative cursor-pointer"
      style={{
        maxWidth: '90%',
        margin: '0 auto',
        position: 'relative',
        padding: '20px',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        backgroundColor: '#f7f7f7',
        cursor: 'pointer',
      }}
    >
      {couponCode}
      
      {/* Copy Icon in the Top Right */}
      <div 
        className="bi bi-copy position-absolute"
        style={{
          top: '10px',
          right: '10px',
          fontSize: '1.5rem',
          color: '#6b6b6b',
        }}
      />

      {/* Optional Copy Success Message */}
      {copySuccess && (
        <div
          style={{
            position: 'absolute',
            bottom: '-20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#4caf50',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '0.9rem',
          }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};

export default CouponCard;
