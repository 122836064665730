import React from 'react'

export const FormCheckbox = ({label = '', value = false, onChange = () => {}, className = ''}) => {

  const id =`form-switch-${label}`

    return(<div className={className}>
    <div className="form-check form-check-custom form-check-solid flex items-center space-x-2" id={id}>
        <input className="form-check-input" type="checkbox" checked={value} id="flexCheckDefault" 
        style={{width: 24, height: 24, marginTop: -2}}
        onChange={e => onChange(e.target.checked) }/>
        <label 
        onClick={() => onChange(!value)}
        className="form-label " style={{margin: '0 10px', color: 'gray'}}>
           {label}
        </label>
    </div>
  </div>
  )
}