import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const itemStyle = "block lg:inline-block mt-2 lg:mt-0 text-gray-700 hover:text-gray-200 cursor-pointer"

  return (
    <nav className="bg-white p-4" >
      <div className=" mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="m-10 mb-2 cursor-pointer min-h-[50px]" onClick={() => window.location.href = '/'}>
      <img src={require('../assets/images/web_logo.png')} style={{maxWidth: '60%'}} />
      {
    window.innerWidth > 800 && !window.location.href?.includes('.com/new') ?    <p className="fs-bold fs-6 text-gray-500 mt-2" >Arizona ESA Curriculum Generator</p> : null
   }
      </div>

        {/* Menu Button (Mobile) */}
        <button
          className="lg:hidden text-gray-700 focus:outline-none"
          onClick={toggleMenu}
        >
          {
            isOpen ? <div className='bi bi-x fs-2 mx-4'></div> 
            :
             <div className='bi bi-list fs-1'></div>
          }
        </button>

        {/* Menu Items */}
        <div
          className={`${isOpen ? 'block' : 'hidden'} w-full lg:flex lg:items-center lg:w-auto lg:space-x-6`}
        >
          <a href="/" className={itemStyle}>
            Home
          </a>
          <a href="/vouchers-purchase" className={`${itemStyle} 
          relative
          flex items-center
          border px-2 py-2 rounded
          font-bold
          bg-gradient-to-tr from-[#FF5E8E] to-[#9089fc] text-white`}>
           
  Vouchers
  <div className='absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 badge bg-info text-white rounded-full px-2 py-1 text-xs'>
    New
  </div>
</a>

          <a href="/vouchers-purchase/check-balance" className={itemStyle}>
            Check Balance
          </a>

          <a href="/how-reimbursement-works" className={itemStyle}>
            Reimbursement
          </a>

          <a href="/new" className={`${itemStyle} `}>
            Create Curriculum
          </a>
          
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
