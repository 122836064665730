import React from 'react';
import { callCouponSvc, CouponBanner, generateUniqueCoupon, getAvailableVouchers } from './utils/vGenerator';
import { COUPON_ITEM_KEY } from './constants';
import { FormInput } from './components/formInput';
import { FormButton } from './components/formButton';


export const Vouchers = () => {

    const [coupon, setCoupon] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [checkedBalance, setCheckedBalance] = React.useState(null);
    const [showBalanceCheck, setShowBalanceCheck] = React.useState(false)
    React.useEffect(() => {
        sessionStorage.removeItem(COUPON_ITEM_KEY)

        if(window?.location?.href?.includes('check-balance')) {
            setShowBalanceCheck(true)
        }
    }, [])

   const vouchers = getAvailableVouchers();


   const renderVoucherCheck = () => {

    return(    <div className='card m-1 p-10 border-indigo-200'>
        <div className=''
        onClick={() => setShowBalanceCheck(false)}
        ><div className='bi bi-x absolute top-2 right-2 fs-3 cursor-pointer'></div></div>
        <FormInput text={coupon} onTextChange={e => setCoupon(e)}
        label='Check Your Voucher Balance' placeholder='Enter voucher code'
        />
        <FormButton 
        loading={loading}
        disabled={loading}
        label='Check Balance'
         onClick={async () => {
            if(coupon?.length && coupon?.includes("NLP-")) {
                setCheckedBalance(null)
                setLoading(true)
              const result =  await callCouponSvc(coupon?.trim(), false, true)
              setLoading(false)

              setCheckedBalance(result)
            } 
        }} />
        {
            checkedBalance ?
            <div className='mt-4'>
                {
                    checkedBalance?.isSuccess ? <div className=' p-3 '>
                       You have used this voucher {checkedBalance?.usageCount} out of {checkedBalance?.maxAllowed}
                      <div className='mt-2 text-success'>
                      You have {+checkedBalance?.maxAllowed - +checkedBalance?.usageCount} free curriculums left.
                      </div>
                       
                    </div>
                    : 
                    <div className='text-danger '>
                        Voucher code is invalid
                    </div>
                }
            </div>
            : null
        }
    </div>)
   }
    
    return(<div>
        <div className='card border-pink-100 m-10'>
        <div className='fs-1 fw-bold  p-10 pb-1'>
            Save Big with Vouchers
        </div>
        <div className='fs-6 pb-2  p-10'>
            <ul>
            <li className='pb-4 text-gray-700 fs-4'>Get a voucher to save money, skip entering payment details every time, and simplify your ESA reimbursement by submitting just one receipt instead of multiple.</li>
            
            </ul>
        </div>

            {
                showBalanceCheck ? renderVoucherCheck() : 
                <div className='underline w-100 align-items-center text-center text-indigo-500 cursor-pointer' 
                onClick={() => setShowBalanceCheck(!showBalanceCheck)}
                >
                    Already purchased a voucher? click to check balance
                </div>
            }

            <div>
                {vouchers?.map((voucher, index) => <CouponBanner text={voucher.name} isBanner ={false}
                grad={index === 0 ? `from-[#FBA0A8] to-[#FF5E8E]` : `from-[#7A86FF] to-[#6B9FFF]`}
                onClick={() =>{
                    const coupon = generateUniqueCoupon(voucher.maxAllowed)
                    sessionStorage.setItem(COUPON_ITEM_KEY, coupon)
                    window.location.href =voucher?.link
                    }}
                description={voucher?.description} diffGrad={index === 1} />)}
            </div>
        </div>
    </div>)
}