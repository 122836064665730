import React from "react";

export const FormInput = ({
  label = "",
  text = "",
  placeholder = "",
  onTextChange = () => {},
  onBlur = () => {},
  isTextArea = false,
  footNote ='',
  disabled = false,
  isRequired = false,
  type='text',
  className = 'mb-10',
  autoFocus = false
}) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the default action (e.g., form submission)
      
      // Add your custom logic here
    }
  };

 
  return (
    <div className={className}>
     { label?.length ?  <label className="form-label mb-3 fw-bold">{label}</label> : null}
      {isTextArea ? (
        <textarea
        onKeyDown={handleKeyDown}
        disabled={disabled}
          value={text}
          className={`form-control form-control-lg form-control-solid ${disabled ? ' bg-transparent' : ''}`}
          style={{borderColor: isRequired ? 'red' : null}}
          placeholder={placeholder}
          onChange={e => {
            if(disabled) return;
            onTextChange(e.target.value)
          }}
        />
      ) : (
        <input
        autoFocus={autoFocus}
        disabled={disabled}
        onKeyDown={handleKeyDown}
          type={type} required
          value={text}
          onBlur={e => onBlur(e.target.value)}
          className={`form-control form-control-lg form-control-solid ${disabled ? ' bg-transparent' : ''}`}
          style={{borderColor: isRequired ? 'red' : null}}
          placeholder={placeholder}
          onChange={e => {
            if(disabled) return;
            onTextChange(e.target.value)
          }}
        />
      )}
      {
        footNote?.length ? <small className="form-text text-muted">{footNote}</small> : null 
      }
    </div>
  );
};
