import React from 'react'
import DataModel from '../DataModel'
import { CouponBanner } from '../utils/vGenerator';
import Navbar from '../nav/Navbar';


export const TopNewCurriculumButton = () => {

    if(DataModel.isNewOrSuccessPage()) {
        return null;
    }

    return (
    <div style={{zIndex: 10000}}>
    <Navbar/>
    </div>
    )
    return( <> {1==2 && <div className="btn  m-10 mb-2 cursor-pointer absolute top-0 right-0 bg-indigo-600 hover:bg-indigo-600" style={{zIndex: 10000}} onClick={() => window.location.href = '/new'}>
   <p style={{color:'white'}}> Create Curriculum</p>
     </div>}

     <CouponBanner/>
     </>
     )
}