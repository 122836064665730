import React from 'react'
import { SAVE_ITEM_KEY } from './constants'
import DataModel from './DataModel'
import { ProgressLoadingView } from './components/progressview';

export const Success = () => {

    const [valid, setValid] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [o, setO] = React.useState(null)
    const process = async () => {

        const s = sessionStorage.getItem(SAVE_ITEM_KEY);
        try {
            if(global.isProcessing) {
                return;
            }
            global.isProcessing = true;
            let obj = JSON.parse(s);
            if(obj.grade) {
                setValid(true)
                setIsLoading(true)
                setO(obj)
                    await DataModel.createCurriculum(obj)
                    setIsLoading(false)
            }
        }
        catch (e) {
        
        window.location.href = '/'
        }
        finally {
            global.isProcessing = false;
        }
    }
    const prefix =  `  Download is complete.`
    const getDownloadDoneMessage = () => {
        
        let msg = `Grab the PDF from your downloads folder and drop it onto your ESA portal as shown below.`
        if(o?.getAsJson) {
            msg = `Grab the document from your downloads folder, fill in student name and application number, and drop it onto your ESA portal as shown below.`
        }
        return msg; 
    }

    React.useEffect(() => {
        process()

    }, [])

    if(!valid) {
        return <></>
    }
    return(<div className=' text-center  m-10 p-10 mt-10 rounded mb-10 mt-5 mb-60 bg-[#0dcaf0] ' style={{maxWidth: '80%', alignSelf:'center', margin: '0 auto', marginBottom: 200, background:isLoading || 1===1?  'rgba(1, 1, 1, .03)': 'rgb(120 235 23 / 12%)'}}>
        <p className={`fs-1 ${isLoading ? ' fw-bold' : ''}`}>{
            isLoading ? ' Creating Your Curriculum. Please wait...' :
            <div>
            <div className='row ' >
            <div className='bi bi-check-all  fw-bold' style={{color: global.green}}>{prefix}</div>
            </div>
            <div className={`h2 m-10 `}>
            {getDownloadDoneMessage()}
            </div>
            </div>
            
            }</p>

            {
                isLoading ? <div className='text-center w-100 p-20 h-[300px]'>
                <ProgressLoadingView/>
                </div>
                :
                <div className='mt-20'>
                

              <img src={require('./assets/images/how_to_upload_curriculum.png')} style={{width: '80%', margin:'0 auto', borderColor: 'black', borderWidth: 1, borderRadius: 12}} />

              <div className='mt-5'>
                <p className='py-5'>Didn't get the document or see an error?</p> <div className='btn btn-info'
                onClick={() => process()}
                >
                    Click here to re-download
                </div>
              </div>
              </div>

            }
    </div>)
}