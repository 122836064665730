import { demoJSON } from "./DataModel";

import { jsPDF } from 'jspdf';

import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import WordDocModel from './WordDocModel';
import EmailModel from "./emailModel";


export default class PDFDocModel {


  static handleConvertToPDF = (data = demoJSON(), fileName = "", studentName  = '', applicationNumber = '', email = '') => {
   // data = demoJSON()
  try{
    data = JSON.parse(data)
  }
  catch {
    return;
  }

  const table = `<table>
    <tr>
      <th style="padding:10px;background-color: #f2f2f2;">Student Name</th>
      <th style="padding:10px;background-color: #f2f2f2;">${studentName}</th>
    </tr>
       <tr>
      <th style="padding:10px;background-color: #ffffff;">ApplicationNumber</th>
      <th style="padding:10px;background-color: #ffffff;">${applicationNumber}</th>
    </tr>
    <tr>
      <th style="padding:10px;background-color: #f2f2f2;">Grade</th>
      <th style="padding:10px;background-color: #f2f2f2;">${data.grade}</th>
    </tr>
  
    <tr>
      <th style="padding:10px;background-color: #ffffff;">Subject</th>
      <th style="padding:10px;background-color: #ffffff;">${data.subject}</th>
    </tr>
    <tr>
      <th style="padding:10px;background-color: #f2f2f2;">Scope and Overview</th>
      <td style="padding:10px;background-color: #f2f2f2;">${data.scopeAndOverView}</td>
    </tr>
    <tr>
      <th style="padding:10px;background-color: #ffffff;">Lesson Plan</th>
      <th style="padding:10px;background-color: #ffffff;">
      ${ data.lessonPlan.map((f) =>
       
        `Week: ${f.week} - ${f.topic}: ${f.activities}<br/>`
       
      )
    }
      </th>
    </tr>
    <tr></tr>
    <tr style="padding:10px;background-color: #ebd534;">
      <th style="padding:10px;background-color: #ebd534; padding-bottom:30px">Supplied materials and Educational Use</th>
      <td style=" padding:10px;" >${data.suppliedMaterialsAndEducationalUse}<br/></td>
      
    </tr>
   
  </table>`

  this.generatePDF(table, fileName, email);
  };

  static generatePDF = async (htmlString = '', fileName = 'curriculum_plan', email) => {
    //htmlString = getDemo()


    const options = {
        filename: `${fileName}.pdf`,
        margin: .5,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    
    const d = document.createElement('div');
    d.innerHTML = htmlString//.trim();
  
    // uncomment the following line to test the pdf result
    //document.body.append(d)
    
    html2pdf().set(options).from(d).save();
    
    //send email
    const pdfBlob = await html2pdf().set(options).from(d).output('blob');
    const fullFileName = `${fileName}.pdf`
    EmailModel.sendCurriculumToEmail(pdfBlob, fullFileName, email, 'application/pdf')
  };
}
