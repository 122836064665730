import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

// List of cities in Arizona
const cities = [
  'Phoenix', 'Tucson', 'Mesa', 'Chandler', 'Scottsdale', 'Glendale',
  'Gilbert', 'Tempe', 'Peoria', 'Surprise', 'Yuma', 'Avondale',
  'Flagstaff', 'Goodyear', 'Buckeye', 'Lake Havasu City', 'Casa Grande',
  'Sierra Vista', 'Maricopa', 'Oro Valley', 'Prescott', 'Bullhead City',
  'Prescott Valley', 'Marana', 'Apache Junction'
];

const getRandomCity = () => {
  const randomIndex = Math.floor(Math.random() * cities.length);
  return cities[randomIndex];
};

const getRandomInterval = () => {
  // Random interval between 15 to 20 seconds
  return Math.floor(Math.random() * (20000 - 15000 + 1)) + 15000;
};

const isWithinTimeRange = () => {
  const now = new Date();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();

  const arizonaOffset = -7; // Arizona is UTC-7
  const arizonaHours = (hours + arizonaOffset + 24) % 24;
  if (arizonaHours > 6 && (arizonaHours < 23 || (arizonaHours === 23 && minutes <= 30))) {
    return true;
  }
  return false;
};

const SocialProofWidget = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (!window.location.href.includes('newlessonplan.com/new') && !window.location.href.includes('/success') && isWithinTimeRange()) {
        toast(`Someone bought a curriculum from\n${getRandomCity()}, AZ.`, {
          position: 'bottom-right',
          icon: <i className="bi bi-bag-check"></i>,
          style: { color: 'gray' }
        });
      }
    }, getRandomInterval());

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Toaster />
    </>
  );
};

export default SocialProofWidget;
