import React from 'react'


export const Help = () => {


    return(<>
    <div className='grid p-10'>

    <p className='h1 fs-bold text-left px-10 '> Important for Approval </p>
    <p className='p-10 fs-5'>When entering the materials on the "New Curriculum" page, try to be as detailed as possible especially if you purchasing items that are not on the list.
        For example, books, instead of just entering "Books", enter the book title with it as well "Books - The Tale of Two Cities".
    </p>

    
     <p className='h1 fs-bold text-left px-10'>   Why do I need a curriculum?</p>
    <p className='p-10 fs-5'>ESA guidelines changes often and that present a risk for rejecting your ESA-based purchases. To avoid that risk, you need to provide a curriculum accompanying each purchase and explaining the intended usages of each item on your purchase list.
     That, in itself, is a tedious task with too many variables, especially when you order things you kids need but are not common items parents usually purchase, which makes the risk of getting your purchase rejected higher if you do it on you own. 
    </p>

    <p className='h1 fs-bold text-left px-10'>   Why are you charging $0.99?</p>
    <p className='p-10 fs-5'>
        As parents, we're truly doing this to help ourselves and other parents with ESA approvals. 
        Realistically, we're making almost no money out of the fee as <code>$0.33</code> is going to Stripe (our payment provider), <code>$0.09</code> is going to AI services, and <code>$0.22</code> is going to a smart formatting system to generate a curriculum that matches what ESA expects.
        
    </p>


     
    <p className='h1 fs-bold text-left px-10 pt-10'> What does this website do?</p>
    <p className='p-10 fs-5'>We generate an approvable PDF file and a curriculum for each item you're intending to purchase with your ESA funds. You simply enter a few details, we generate the curriculum PDF for you, then you upload that PDF to ESA. That's it!
    </p>


    <p className='h1 fs-bold text-left px-10 pt-10'> What do I get after I pay $0.99?</p>
    <p className='p-10 fs-5'>
    Will give you a well-crafted and formatted PDF (or a word doc if you chose not to enter student name) according to ESA guidelines, similar to this sample:
    </p>

    <img src={require('./assets/images/Sample Report.png')} style={{width: '80%', margin:'0 auto', borderColor: 'black', borderWidth: 1, borderRadius: 12}} />



  
    <p className='h1 fs-bold text-left px-10 pt-10 mt-5'> How do I upload the curriculum PDF to ESA?</p>
    <p className='p-10 fs-5'>
    Once we give you the curriculum PDF, simply drag and drop it onto your ESA curriculum information page as shown in the below image:
    </p>

    <img src={require('./assets/images/how_to_upload_curriculum.png')} style={{width: '80%', margin:'0 auto', borderColor: 'black', borderWidth: 1, borderRadius: 12}} />




    <p className='h1 fs-bold text-left px-10 pt-10 mt-20'> Can I do this at home?</p>
    <p className='p-10 fs-5'>
        You can generate a curriculum with AI products, then you need copy the result, clean it up, format it, put it in Canva, format it into readable sections, then download from Canva, and print it, take it to ESA and upload it. 
        All that cost you time and effort. That's if you remember all the required variables from ESA. The worst part, you risk getting rejected by ignoring important info ESA expect from you which we took months to hone in on. 
        Lastly, we made it as cheap as it gets. For only $0.99 we do all the above for you, so you only have to take the PDF and upload it to ESA, which in total cost you less than 30 seconds.
    </p>

    </div>
    </>)
}